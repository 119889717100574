<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>活动管理</el-breadcrumb-item>
            <el-breadcrumb-item>活动列表</el-breadcrumb-item>
            <el-breadcrumb-item>活动详情</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="box-card">
            <!-- 搜索与添加区域 -->



            <el-row>
                <el-col :span="10">
                    <el-input placeholder="请输入内容">
                        <el-button slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="10">
                    <el-button type="primary ">添加</el-button>
                </el-col>
            </el-row>
        </el-card>
        <!-- 客户列表区域 -->
        <el-table :data="userlist" highlight-current-row>
            <el-table-column type="index" label="序号" width='100px'></el-table-column>
            <el-table-column prop="name" label="姓名" width='200px'></el-table-column>
            <el-table-column prop="email" label="邮箱" width='200px'></el-table-column>
            <el-table-column prop="tel" label="联系方式" width='200px'></el-table-column>
            <el-table-column prop="job" label="角色" width='200px'></el-table-column>
            <el-table-column prop="user_state" label="状态" width='200px'>
                {{ user_state + '3' }}
                <el-switch v-model="user_state">
                </el-switch>


            </el-table-column>
        </el-table>
    </div>
</template>
<script>
export default {
    data() {
        return {
            userlist: [],
        }
    },
    created() {
        this.getUserlist()
    },
    methods: {
        async getUserlist() {
            const { data: res } = await this.$http.get('/selectuserlist')
            this.$data.userlist = res.data





        }
    }
}
</script>
<style>
.el-breadcrumb {
    margin: 15px;
    font-size: 15px;
}

.el-card {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15) !important
}
</style>