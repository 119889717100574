import Vue from 'vue'
import App from './App.vue'
import router from '../router'
import './plugins/element.js'
import './assets/global.css'
import ElementUI from 'element-ui'
import axios from 'axios'

axios.defaults.baseURL = 'http://47.103.159.100:8080/api'
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
})
Vue.prototype.$http = axios


Vue.config.productionTip = false
Vue.use(ElementUI)


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

