<template>

    <el-container class="home-container">
        <el-header>
            <div>
                <img src="/icon/icon.jpg" width="50px" height="50px">
                <span>管理系统</span>

            </div>
            <el-button type="info" @click="logout">退出</el-button>
        </el-header>
        <el-container>
            <el-aside :width="isCollapse ? '64px' : '200px'">
                <div class="toggle-button" @click='toggleCollapse'>||||</div>
                <!-- 侧边栏 -->
                <el-menu router :collapse-transitionb="true" :collapse="isCollapse" background-color="#545c64"
                    text-color="#fff" active-text-color="#ffd04b" :default-active="activePath">
                    <!-- 1级菜单  -->
                    <el-submenu :index="item.mainmenusid + ''" v-for="item in mainMenus" :key="item.mainmenusid">
                        <!-- 一级菜单模板区域 -->
                        <template slot="title">
                            <!-- 图标 -->
                            <i class="el-icon-location"></i>
                            <span>{{ item.mainmenuname }}</span>
                        </template>

                        <el-menu-item :index="'/' + subItem.path + ''" v-for="subItem in item.children "
                            :key="subItem.mainmenusid" @click="saveNavState('/' + subItem.path + '')">
                            <!-- 图标 -->
                            <i class="el-icon-location"></i>
                            <span>{{ subItem.mainmenuname }}</span>
                        </el-menu-item>
                    </el-submenu>

                </el-menu>

            </el-aside>
            <el-main>
                <router-view>

                </router-view>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
export default {

    data() {
        return {
            mainMenus: {},
            isCollapse: false,
            // 被激活的地址
            activePath: ''
        }

    },
    created() {
        this.getMenuList()
        console.log(this.$data.mainMenus);
        this.$data.activePath = window.sessionStorage.getItem('activePath')

    },
    methods: {
        logout() {
            window.sessionStorage.clear()
            this.$router.push('/login')
        },
        async getMenuList() {
            // 数据库获得列表所有数组
            const { data: res } = await this.$http.get('/Menulist')
            // 赋值给定义的数组
            this.$data.mainMenus = res.data

        },
        toggleCollapse() {
            this.$data.isCollapse = !this.$data.isCollapse
        },
        // 保存链接的激活状态
        saveNavState(activePath) {
            window.sessionStorage.setItem('activePath', activePath)
            this.$data.activePath = activePath
        }

    }
} 
</script>
<style>
.el-header {
    background-color: #363d40;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
    color: #ffff;
    font-size: 20px;

    >div {
        display: flex;
        align-items: center;

        span {
            margin-left: 15px;
        }
    }
}

.el-aside {
    background-color: #383a3d43;
}

.el-main {
    background-color: #e9edf0;
}

.home-container {
    height: 100%;
    background-color: #545c64;

}

.toggle-button {
    background-color: #272d35;
    font-size: 10px;
    line-height: 24px;
    color: #f8f9fa;
    text-align: center;
    letter-spacing: 0.2em;
    padding: 15px;
    cursor: pointer;
}
</style>