import Vue from "vue"
import Router from 'vue-router'
import Login from './src/components/login.vue'
import home from './src/components/home.vue'
import welcome from './src/components/welecom.vue'
import toralinventory from './src/components/store/toralinventory.vue'
import beputinstorage from './src/components/store/beputinstorage.vue'
import deliveryofcargofromstorage from './src/components/store/deliveryofcargofromstorage.vue'
import userlist from './src/components/administor/userlist.vue'
Vue.use(Router)
const router = new Router({
    routes: [
        //默认地址转到登录页面
        { path: '/', redirect: '/login' },
        { path: '/login', component: Login },
        {
            path: '/home',
            component: home,
            redirect: '/home/welcome',
            children: [
                { path: 'welcome', component: welcome },
                { path: '/toralinventory', component: toralinventory },
                { path: '/beputinstorage', component: beputinstorage },
                { path: '/deliveryofcargofromstorage', component: deliveryofcargofromstorage },
                { path: '/userlist', component: userlist },
            ]
        }
    ]
})
router.beforeEach((to, from, next) => {
    // to 表示将要访问的路径
    // from 代表从哪个路径跳转回来
    // next 是一个函数 表示放行
    if (to.path == '/login') return next()
    // 获取token
    const tokenStr = window.sessionStorage.getItem('token')
    if (!tokenStr) return next('/login')
    next()
})
export default router