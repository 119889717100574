<template>
    <div class="container">
        <div class="login-wrapper">
            <div class="header">Login</div>
            <div class="form-wrapper">
                <el-form :model="loginForm" :rules="loginFormRules" ref="loginFromRef">

                    <el-form-item prop="username">
                        <el-input v-model="loginForm.username" type="text" name="username" placeholder="username"
                            class="input-item"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="loginForm.password" type="password" name="password" placeholder="password"
                            class="input-item"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="btn" @click="login">登录</el-button>
                    </el-form-item>


                    <div class="msg">
                        Don't have account?
                        <a href="#">Sign up</a>
                    </div>
                </el-form>
            </div>
        </div>
    </div>


    <!-- <div id="regeister">
            <p>3</p>

        </div> -->



</template>
<script>

export default ({
    data() {
        return {
            loginForm: {
                username: 'fanxiansheng',
                password: '123123',
            },

            //    表单验证规则
            loginFormRules: {
                // 验证用户名是否合法
                username: [
                    { required: true, message: '请输入账号', trigger: 'blur' },
                    { min: 3, max: 16, message: "用户名长度3-4个字符", trigger: 'blur' }
                ],
                // 验证用户名是否合法
                password: [
                    { required: true, message: '密码不能为空', trigger: 'blur' },
                    { min: 6, max: 12, message: "用户名长度6-12个字符", trigger: 'blur' }
                ]
            },

        }
    },
    methods: {

        login() {
            // 校验内容
            this.$refs.loginFromRef.validate(async valid => {
                if (!valid) return
                const { data: res } = await this.$http.get('/login',
                    {
                        params:
                        {
                            username: this.$data.loginForm.username,
                            password: this.$data.loginForm.password
                        }
                    })

                if (res.status !== 0) return this.$message.error('登陆失败')
                this.$message.success('登陆成功')
                window.sessionStorage.setItem('token', res.token)
                this.$router.push('/home')

            })
        }
    }
})
</script>
<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
}

body {
    height: 100%;
}

.container {
    height: 100%;
    background-image: linear-gradient(to right, #fbc2eb, #a6c1ee);
}

.login-wrapper {
    background-color: #fff;
    width: 358px;
    height: 588px;
    border-radius: 15px;
    padding: 0 50px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.header {
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    line-height: 200px;
}

.input-item {
    // display: block;
    // width: 100%;

    // border: 1px solid red;
    // margin-bottom: 20px;
    // border: 0;
    // padding: 10px;
    // // border-bottom: 1px solid rgb(128, 125, 125);
    // font-size: 15px;
    // outline: none;
    margin-top: 30px
}

.input-item:placeholder {
    text-transform: uppercase;
}

.btn {
    text-align: center;
    padding: 10px;
    width: 100%;
    margin-top: 40px;
    background-image: linear-gradient(to right, #a6c1ee, #fbc2eb);
    color: #fff;
    cursor: pointer;
}

.msg {
    text-align: center;
    line-height: 88px;
}

a {
    text-decoration-line: none;
    color: #abc1ee;
}
</style>